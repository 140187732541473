import request from '@/utils/request'
let resquest = "/api/"

//客户
export function costomerGetValidList(data) {
    return request({
        url: `${resquest}base/costomer/getValidList`,
        method: 'post',
        data
    })
}

//业务报表-询价总览表
export function businessGetInquiryOverviewList(data) {
    return request({
        url: `${resquest}report/business/getInquiryOverviewList`,
        method: 'post',
        data
    })
}

//业务报表-客户询价总览表
export function businessGetCustomerInquiryList(data) {
    return request({
        url: `${resquest}report/business/getCustomerInquiryList`,
        method: 'post',
        data
    })
}

//产品报表-询价总览表
export function productGetInquiryOverviewList(data) {
    return request({
        url: `${resquest}report/product/getInquiryOverviewList`,
        method: 'post',
        data
    })
}

//产品报表-散货询价总览表
export function productGetBulkCargoInquiryList(data) {
    return request({
        url: `${resquest}report/product/getBulkCargoInquiryList`,
        method: 'post',
        data
    })
}

//产品报表-散货询价区级总览表
export function productAreasGetBulkCargoInquiryList(data) {
    return request({
        url: `${resquest}report/product/areas/getBulkCargoInquiryList`,
        method: 'post',
        data
    })
}

//产品报表-散货询价区级总览表导出
export function productAreasExportBulkCargoInquiryList(data) {
    return request({
        url: `${resquest}report/product/areas/exportBulkCargoInquiryList`,
        method: 'post',
        data
    })
}

//产品报表-拖车询价总览表
export function productGetTrailerInquiryList(data) {
    return request({
        url: `${resquest}report/product/getTrailerInquiryList`,
        method: 'post',
        data
    })
}

//城市
export function cityGetValidList(data) {
    return request({
        url: `${resquest}base/city/getValidList`,
        method: 'post',
        data
    })
}